.post-feature-image img {
  margin: 0 0 3vw;
  width: 100%;
  height: 500px;
  -o-object-fit: cover;
  object-fit: cover;
}

.post-full-content {
  background: #fff;
  width: 70%;
  margin-left: 3%;
}

.content-title {
  margin: 0 0 0.8em;
  font-size: 5rem;
}

.content-body {
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
}

.accessCard {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  background-color: #15171a !important;
  border-radius: 0px !important;
  color: white !important;
}

.accessCardBody h2 {
  margin-top: 0px;
}

.loadwrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadText {
  color: black;
  display: inline-block;
  margin-left: 5px;
  font-size: 20px;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
  color: black;
}

.bounceball::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: black;
  transform-origin: 50%;
  animation: bounce 500ms alternate infinite ease;
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

/* .switchBtn {
  text-align: center;
} */

.switch.btn {
  min-height: 30px !important;
  min-width: 80px !important;
  text-align: center !important;
}

.switch-handle {
  display: none !important;
}

.switch {
  position: fixed !important;
  bottom: 15px !important;
  right: 60px !important;
  z-index: 99999;
  border: unset !important;
  border-radius: 0px !important;
}

.switch-on,
.switch-off {
  font-size: 12px !important;
  font-family: sans-serif !important;
  background-color: black !important;
}

.switch-off.btn {
  padding-left: 0.5em !important;
}

/*Accordion styles*/

/* .accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  background-color: #15171a;
  color: white;
} */

.accordion-item:nth-child(odd) .accordion-button {
  background-color: #d3d3d3;
  color: white;
  padding: 10px;
}

.accordion-item:nth-child(even) .accordion-button {
  background: rgba(21, 23, 26, 0.5);
  color: white;
  padding: 10px;
}

.accordion-flush .accordion-collapse {
  border: 1px solid #15171a;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

/* Pre code CSS */

.content-body pre {
  margin: 0.4em 0 1.8em;
  font-size: 0.6rem;
  line-height: 1.4em;
  white-space: pre-wrap;
  padding: 20px;
  background: #15171a;
  color: chartreuse;
  border-radius: 0px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  position: relative;
}

/* 
.content-body output {
  width: 100%;
  margin: 0.4em 0 1.8em;
  font-size: 0.6rem;
  line-height: 1.4em;
  white-space: pre-wrap;
  padding: 20px;
  background: #f0f0f0;
  color: black;
  border-radius: 0px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  position: relative;
} */

.content-body output {
  /* Your existing styles */
  width: 100%;
  margin: 0.4em 0 1.8em;
  font-size: 0.6rem;
  line-height: 1.4em;
  white-space: pre-wrap;
  padding: 20px;
  background: #f0f0f0;
  color: black;
  border-radius: 0px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  position: relative;
}

.content-body output code {
  /* Specific styles for <code> elements within .content-body output */
  font-family: 'Courier New', Courier, monospace; /* Preserving monospace font */
  background-color: #eee; /* Optional: different background for code elements */
  padding: 0px 0px; /* Optional: padding for code elements */
  border-radius: 0px; /* Optional: rounded corners for code elements */
  color: #d63384; /* Optional: different text color for code elements */
}



.copyCodeButton {
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 5px;
}

/* .bookTooltip {
  position: relative;
  display: inline-block;
} */

/* .bookTooltip .bookTooltipText {
  visibility: hidden;
  background-color: black;
  opacity: 0.8;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 5px;

  
  position: absolute;
  z-index: 1;
} */

/* .bookTooltip:hover .bookTooltipText {
  visibility: visible;
} */

/* html {
    font-size: calc(15px + 0.390625vw);
} */

.tooltip2 {
  position: relative;
  display: inline-block;
  padding: 0.15em 0.15em;
  background: #fbedc3;
  font-style: italic;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 250px; /* Updated width */
  background-color: black;
  opacity: 0.85;
  color: #fff;
  text-align: center;
  border-radius: 0;
  padding: 5px 5px;
  font-size: 13px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -125px; /* Half of the new width to center the tooltip */
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
}

.tooltip2 .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.effectLinks {
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: white;
  background: rgba(21, 23, 26, 0.5);
  padding: 10px;
  width: 100%;
}

.effectLinks::after {
  content: attr(data-replace);
  position: absolute;
  left: 0;
  padding-left: 10px;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1), opacity 0.3s ease; /* added opacity transition */
  color: white;
  background: rgba(21, 23, 26, 1);
  opacity: 0; /* added this line */
}

.effectLinks:hover::after {
  transform: translate3d(0, 0, 0);
  opacity: 1; /* added this line */
}

.effectLinks:hover {
  background: rgba(21, 23, 26, 1);
  color: rgb(43, 150, 226);
}

.effectLinks::after {
  content: attr(data-replace);
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: white;
  background: rgba(21, 23, 26, 1);
}

.effectLinks:hover::after {
  transform: translate3d(0, 0, 0);
  color: rgb(43, 150, 226);
}

.effectLinks span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.effectLinks:hover span {
  transform: translate3d(-200%, 0, 0);
}

.effectLinksTable {
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: rgb(57, 56, 56); /* Changed from white to black */
  background: none; /* Removed background color */
  padding: 0px;
  width: 100%;
}

.effectLinksTable::after {
  content: attr(data-replace);
  position: absolute;
  left: 0;
  padding-left: 10px;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1), opacity 0.3s ease; /* added opacity transition */
  color: black;
  background: none;
  opacity: 0; /* added this line */
}

.effectLinksTable:hover::after {
  transform: translate3d(0, 0, 0);
  opacity: 1; /* added this line */
}

.effectLinksTable:hover {
  background: none; /* Removed background color */
  /* color: rgb(43, 150, 226) */
  color: white;
}

.effectLinksTable::after {
  content: attr(data-replace);
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.effectLinksTable:hover::after {
  transform: translate3d(0, 0, 0);
  /* color: rgb(43, 150, 226) */
  color: white;
}

.effectLinksTable span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.effectLinksTable:hover span {
  transform: translate3d(-200%, 0, 0);
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background: rgba(21, 23, 26, 0.5);
  color: white;
}
